import styled from 'styled-components';

export const ScGetGogetaWrapper = styled.div`
	margin: 60px 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
	p {
		max-width: 315px;
		margin: 0 auto;
	}
	@media (min-width: 768px) {
		p {
			max-width: 900px;
		}
	}
	@media (min-width: 1220px) {
		gap: 55px;
		margin: 120px 0;
	}
	@media (min-width: 1920px) {
		gap: 55px;
		margin: 200px 0;
	}
`;
