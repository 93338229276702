import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/bread-home.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button/index';
import { ScLargeTitle } from '../../components/Title/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import {
	ScComingSoonButton,
	ScEmployeeProductHeroButtons,
	ScEmployeeProductHeroWrapper,
} from './styled';

export const Hero = ({ isEmployer = false }: { isEmployer?: boolean }) => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
			<ScTop background={theme.colors.toasted}>
				<ScContainer>
					<ScEmployeeProductHeroWrapper>
						<MotionDiv variants={fadeInUp}>
							<ScLargeTitle className="hero-title" centered color={theme.colors.wheat}>
								same dough
								<br />
								more bread
							</ScLargeTitle>
						</MotionDiv>
						<MotionDiv className="image" variants={fadeInLeft}>
							<img src={image} alt="bread" />
						</MotionDiv>
						<MotionDiv variants={fadeInUp} className="buttons">
							<ScEmployeeProductHeroButtons>
								<Link to={isEmployer ? '/employer-cycling/' : '/employee-cycling/'}>
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										Bike
									</CustomButton>
								</Link>
								<Link to={isEmployer ? '/employer-nursery/' : '/employee-nursery/'}>
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										Nursery
									</CustomButton>
								</Link>
								<ScComingSoonButton>
									<CustomButton
										color={theme.colors.darkText}
										background={theme.colors.wheat}
										disabled
									>
										Tech*
									</CustomButton>
									<p>*Coming soon</p>
								</ScComingSoonButton>
							</ScEmployeeProductHeroButtons>
						</MotionDiv>
					</ScEmployeeProductHeroWrapper>
				</ScContainer>
			</ScTop>
		</motion.section>
	);
};
