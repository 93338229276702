import React from 'react';
import { motion } from 'framer-motion';

import { animationContainer, fadeInUp } from '../../..//animations/variants';
import { ScContainer } from '../../..//components/container/styled';
import { MotionDiv } from '../../../animations/components';
import { ScParagraph } from '../../../components/Paragraph/styled';
import { ScTitle } from '../../../components/Title/styled';
import useContentAnimation from '../../../hooks/use-content-animation';

import { ScGetGogetaWrapper } from './styled';

export const GetGogeta = ({
	isEmployer = false,
	isPartnerPage = false,
}: {
	isEmployer?: boolean;
	isPartnerPage?: boolean;
}) => {
	const { ref, controls } = useContentAnimation();

	const text = isPartnerPage ? (
		<p>
			Let’s make it a good work day.
			<br />
			It's easier than you think to get an extra slice
			<br /> for your customers - and for you.
		</p>
	) : isEmployer ? (
		<p>
			Let’s make it a good work day. Your team can take home a bigger slice of their salary by
			paying for nursery costs, food shopping, or a new bike before they get taxed. Great benefits
			for them, and completely free for you.
		</p>
	) : (
		<>
			<p>Let’s make it a good work day.</p>
			<p>
				You can take home a bigger slice of your salary by paying for nursery costs or a new bike
				before getting taxed.
			</p>
		</>
	);

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScGetGogetaWrapper>
					<MotionDiv variants={fadeInUp}>
						<ScTitle isMobileLarge centered>
							Use your loaf. Get Gogeta.
						</ScTitle>
					</MotionDiv>
					<MotionDiv variants={fadeInUp}>
						<ScParagraph centered ref={ref}>
							{text}
						</ScParagraph>
					</MotionDiv>
				</ScGetGogetaWrapper>
			</ScContainer>
		</motion.section>
	);
};
